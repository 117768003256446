.coupon-v1 {
	.coupon-field {
		.form-control {
			margin-right: 10px;
			border-radius: 5px !important;
		}
	}

	padding: 20px 20px;
	border: 1px solid var(--color-grey-normal);
	background-color: var(--color-white);

	.coupon-field {
		margin-top: 20px;
	}
}